module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-MNHRLZ3"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Diamentowe Zacisze","short_name":"Diamentowe Zacisze","lang":"pl","start_url":"/","background_color":"#fff","theme_color":"#E7B52E","display":"standalone","icon":"src/assets/favicons/favicon-32x32.png","cache_busting_mode":"none","icons":[{"src":"src/assets/favicons/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"src/assets/favicons/maskable_icon_x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"src/assets/favicons/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/favicon*","**/maskable_icon_*"]},"precachePages":["/"]},
    }]
